import React, {Component} from 'react';
import './App.css';
import {BACK, CANCEL, FILTER, SEARCH, SIGN_OUT} from '../components/Header';
import SimpleCheckList from "../components/SimpleCheckList";
import TwoLevelCheckList from "./TwoLevelCheckList";
import {Redirect, Route, Switch} from 'react-router-dom';
import Login from '../components/Login';
import MaterialWrapper from "../layouts/MaterialWrapper";
import WithFilter from "../layouts/WithFilter";
import TagsFilter from "../components/TagsFilter";
import {
  addRemove,
  addRemoveKeys,
  filterObj,
  intersection,
  isEmptyArr,
  isEmptySet,
  mapObj,
  removeKeys,
  subtraction,
  uniqueInArr,
  updateKey
} from "../static/functions";
import {DOCUMENT} from "../components/Fab";
import {dataFromQuery, dataToQuery} from "../utils/queries";
import {cancelJob, checkJobStatus, fetchDetailedData, fetchInitialData, generateDocuments} from "../requests";
import AlertDialog from "../components/AlertDialog";
import navigate from '../utils/navigate';

const TAGS = 't';
const JOB = 'j';
const QUERY = 'q';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      dialog: null,
      progress: -1,
      services: {},
      tags: []
    };
    this.activeJobId = null;
  }

  setServices(services) {
    this.setState({
      ...this.state,
      services,
      tags: Object.values(services).flatMap(s => s.tags).filter(uniqueInArr).sort(),
      progress: -1,
    });
  }

  setProgress(progress) {
    if (this.state.progress !== progress) {
      this.setState({...this.state, progress});
    }
  }

  showMessage(message) {
    this.setState({...this.state, progress: -1, message});
  }

  showDialog(dialog) {
    this.setState({...this.state, dialog});
  }

  call(callable) {
    return callable(m => this.showMessage(m), p => this.setProgress(p));
  }

  render() {
    const {progress, services} = this.state;
    return (
      <Switch>
        <Route exact path='/' component={() => <Redirect to='/services'/>}/>}/>
        <Route path='/login' component={props =>
          <MaterialWrapper
            header={{progress}}>
            <Login
              disabled={progress >= 0}
              badCredentials={props.location.search === '?error'}/>
          </MaterialWrapper>
        }/>
        <Route path='/services' render={props => {
          const {history, location} = props;
          const queryData = dataFromQuery(location.search);
          const selection = intersection(Object.keys(services), Object.keys(queryData));
          const tagsSelection = new Set(queryData[TAGS]);
          const tagsMatched = new Set(Object.keys(services).filter(id => !isEmptySet(intersection(services[id].tags, tagsSelection))));
          const search = queryData[QUERY];
          const job = !!queryData[JOB] && !isEmptyArr(queryData[JOB]) ? queryData[JOB][0] : null;
          const pushWithData = data => history.push(location.pathname + dataToQuery(data));
          setTimeout(() => {
              if (isEmptyArr(Object.keys(services))) {
                this.setProgress(0);
                this.call(fetchInitialData)(s => this.setServices(s));
              }
              this.call(checkJobStatus)(
                job,
                () => this.activeJobId,
                jobId => this.activeJobId = jobId,
                () => pushWithData(removeKeys(queryData, [JOB]))
              );
            }
          );
          return (
            <MaterialWrapper
              header={{
                progress: progress,
                search: !!search ? search.join(', ') : undefined,
                onSearch: q => pushWithData(updateKey(queryData, QUERY, q.split(',').map(s => s.trim()))),
                onSearchClose: () => pushWithData(removeKeys(queryData, [QUERY])),
                buttons: {
                  left: !!job
                    ? [{icon: CANCEL, click: () => this.showDialog('cancel')}]
                    : !isEmptySet(selection)
                      ? [{icon: BACK, click: () => pushWithData(removeKeys(queryData, Object.keys(services)))}]
                      : [],
                  right: [
                    {icon: SIGN_OUT, click: () => this.showDialog('logout')},
                    ...!job ? [
                      {icon: FILTER, click: () => pushWithData(updateKey(queryData, TAGS, []))},
                      {icon: SEARCH, click: () => pushWithData(updateKey(queryData, QUERY, []))}
                    ] : []
                  ]
                }
              }}
              fab={{
                icon: DOCUMENT,
                text: 'SELECT',
                hide: isEmptySet(selection),
                disabled: this.state.progress !== -1,
                onClick: () => {
                  if (Object.keys(services)
                    .filter(sid => selection.has(sid))
                    .map(sid => services[sid].documents)
                    .some(docs => !docs)) {
                    this.setProgress(0);
                    this.call(fetchDetailedData)(selection, s => {
                      this.setServices(s);
                      const preselect = mapObj(
                        filterObj(s, key => selection.has(key)),
                        (key, value) => [key, Object.keys(value.documents)]
                      );
                      history.push('/documents' + dataToQuery(removeKeys({...queryData, ...preselect}, [QUERY])));
                    });
                  } else {
                    const preselect = mapObj(
                      filterObj(services, key => selection.has(key)),
                      (key, value) => [key, Object.keys(value.documents)]
                    );
                    history.push('/documents' + dataToQuery(removeKeys({...queryData, ...preselect}, [QUERY])));
                  }
                }
              }}
              snackbar={{
                message: this.state.message,
                onClose: () => this.showMessage(null)
              }}>
              <AlertDialog
                id={'cancel'}
                open={this.state.dialog === 'cancel'}
                title='Are you sure you want to stop generation process?' text='All generated documents will be lost.'
                primary='Stop' secondary='Dismiss' focus='secondary'
                onPrimary={() => this.call(cancelJob)(job, () => {
                  pushWithData(removeKeys(queryData, [JOB]));
                  this.showDialog(null);
                })}
                onSecondary={() => this.showDialog(null)}
                onClose={() => this.showDialog(null)}/>
              <AlertDialog
                id={'logout'}
                open={this.state.dialog === 'logout'}
                title='Are you sure you want to sign out' text=''
                primary='Sign Out' secondary='Cancel' focus='primary'
                onPrimary={() => navigate('/logout')}
                onSecondary={() => this.showDialog(null)}
                onClose={() => this.showDialog(null)}/>
              <WithFilter
                hide={!(TAGS in queryData)}
                component={TagsFilter}
                filter={{
                  tags: this.state.tags,
                  selected: tagsSelection,
                  disable: !!job,
                  extraText: !isEmptyArr(Object.keys(services)) && !isEmptySet(tagsSelection)
                    ? isEmptySet(subtraction(tagsMatched, intersection(selection, tagsMatched)))
                      ? `Unselect matched (${tagsMatched.size})`
                      : `Select matched (${tagsMatched.size})`
                    : "",
                  extraAction: () => {
                    const unselected = subtraction(tagsMatched, selection);
                    pushWithData(addRemoveKeys(queryData,
                      isEmptySet(unselected) || unselected === tagsMatched ? tagsMatched : unselected,
                      []));
                  },
                  onSelect: key => pushWithData(updateKey(queryData, TAGS, [...addRemove(tagsSelection, key)])),
                  onClose: () => pushWithData(removeKeys(queryData, [TAGS]))
                }}>
                <SimpleCheckList
                  selected={Object.keys(queryData)}
                  items={Object.keys(services)
                    .filter(id => isEmptySet(tagsSelection) || tagsMatched.has(id))
                    .filter(id => !search || isEmptyArr(search.filter(q => q !== ''))    // no search or empty field
                      || search.filter(q => q !== '').some(q => services[id].name.toLowerCase().includes(q.toLowerCase())))   // search query
                    .map(id => [id, services[id].name, services[id].tags.join(", ")])}
                  onSelect={key => pushWithData(addRemoveKeys(queryData, [key], []))}
                  disabled={progress >= 0}/>
              </WithFilter>
            </MaterialWrapper>
          );
        }}/>
        <Route path='/documents' render={props => {
          const {history, location} = props;
          const queryData = dataFromQuery(location.search);
          const selection = intersection(Object.keys(services), Object.keys(queryData));
          const search = queryData[QUERY];
          const job = !!queryData[JOB] && !isEmptyArr(queryData[JOB]) ? queryData[JOB][0] : null;
          const pushWithData = data => history.push(location.pathname + dataToQuery(data));
          setTimeout(() => {
              if (isEmptyArr(Object.keys(services))) {
                this.setProgress(0);
                this.call(fetchDetailedData)(Object.keys(queryData), s => this.setServices(s));
              }
              this.call(checkJobStatus)(
                job,
                () => this.activeJobId,
                jobId => this.activeJobId = jobId,
                () => pushWithData(removeKeys(queryData, [JOB]))
              );
            }
          );
          return (
            <MaterialWrapper
              header={{
                progress: progress,
                search: !!search ? search.join(', ') : undefined,
                onSearch: q => pushWithData(updateKey(queryData, QUERY, q.split(',').map(s => s.trim()))),
                onSearchClose: () => pushWithData(removeKeys(queryData, [QUERY])),
                buttons: {
                  left: !!job
                    ? [{icon: CANCEL, click: () => this.showDialog('cancel')}]
                    : !isEmptySet(selection)
                      ? [{
                        icon: BACK, click: () => history.push('/services' +
                          dataToQuery(mapObj(queryData, (key, value) => selection.has(key) ? [key, []] : [key, value])))
                      }]
                      : [],
                  right: [
                    {icon: SIGN_OUT, click: () => this.showDialog('logout')},
                    ...!job ? [
                      {icon: SEARCH, click: () => pushWithData(updateKey(queryData, QUERY, []))}
                    ] : []
                  ]
                }
              }}
              fab={{
                icon: DOCUMENT,
                text: 'Generate',
                hide: [...selection].map(id => queryData[id]).every(isEmptyArr),
                disabled: this.state.progress !== -1,
                onClick: () => {
                  this.call(generateDocuments)(filterObj(queryData, key => selection.has(key)),
                    jobId => pushWithData(updateKey(queryData, JOB, [jobId])));
                }
              }}
              snackbar={{
                message: this.state.message,
                onClose: () => this.showMessage(null)
              }}>
              <AlertDialog
                id={'cancel'}
                open={this.state.dialog === 'cancel'}
                title='Are you sure you want to stop generation process?' text='All generated documents will be lost.'
                primary='Stop' secondary='Dismiss' focus='secondary'
                onPrimary={() => this.call(cancelJob)(job, () => {
                  pushWithData(removeKeys(queryData, [JOB]));
                  this.showDialog(null);
                })}
                onSecondary={() => this.showDialog(null)}
                onClose={() => this.showDialog(null)}/>
              <AlertDialog
                id={'logout'}
                open={this.state.dialog === 'logout'}
                title='Are you sure you want to sign out' text=''
                primary='Sign Out' secondary='Cancel' focus='primary'
                onPrimary={() => navigate('/logout')}
                onSecondary={() => this.showDialog(null)}
                onClose={() => this.showDialog(null)}/>
              <TwoLevelCheckList
                selected={Object.keys(queryData)}
                forceExpand={!!search}
                items={Object.keys(services)
                  .filter(serviceId => selection.has(serviceId))
                  .map(serviceId => {
                    const service = services[serviceId];
                    const documents = service.documents;
                    const selectedDocs = queryData[serviceId].filter(i => Object.keys(documents).includes(i));
                    const selected = Object.keys(documents).length > 0 ? selectedDocs.length / Object.keys(documents).length : 0;
                    return ({
                      path: [serviceId],
                      name: service.name,
                      selected: selected,
                      items:
                        Object.keys(documents)
                          .map(docId => ({
                            path: [serviceId, docId],
                            name: documents[docId],
                            selected: selectedDocs.includes(docId)
                          }))
                          .filter(doc => !search || isEmptyArr(search.filter(q => q !== ''))    // no search or empty field
                            || search.filter(q => q !== '').some(q => doc.name.toLowerCase().includes(q.toLowerCase()))) // actual search results
                          .sort((a, b) => a.name.localeCompare(b.name))
                    });
                  }).filter(service => !isEmptyArr(service.items))
                }
                onSelect={path => {
                  const [serviceId, docId] = path;
                  if (!!docId) {    // one document
                    const updated = addRemove(queryData[serviceId], docId);
                    pushWithData(updateKey(queryData, serviceId, [...updated]));
                  } else {        // all documents
                    const selected = queryData[serviceId];
                    const all = Object.keys(services[serviceId].documents);
                    if (isEmptySet(subtraction(all, selected))) {
                      pushWithData(updateKey(queryData, serviceId, []));
                    } else {
                      pushWithData(updateKey(queryData, serviceId, all));
                    }
                  }
                }}
                disabled={progress >= 0}/>
            </MaterialWrapper>
          );
        }}/>
      </Switch>
    );
  }
}

export default App;
