import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Header from "../components/Header";
import Fab from "../components/Fab";
import Snackbar from "../components/Snackbar"

const styles = theme => ({
  headerPadding: {
    paddingTop: theme.spacing.unit * 8,
  }
});

const MaterialWrapper = props => {
  const {classes, header, fab, snackbar} = props;
  return (
    <div id='material-wrap'>
      {!!header && <Header {...header}/>}
      <div id='material-wrap-children' className={!!header ? classes.headerPadding : ''}>
        {props.children}
      </div>
      {!!fab && <Fab {...fab}/>}
      {!!snackbar && <Snackbar {...snackbar}/>}
    </div>
  );
};

MaterialWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.shape(Header.propTypes),
  fab: PropTypes.shape(Fab.propTypes),
  snackbar: PropTypes.shape(Snackbar.propTypes),
};

export default withStyles(styles)(MaterialWrapper);

