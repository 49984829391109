export default function navigate(url, download) {
  const a = document.createElement('a');
  a.href = url;
  if (!!download) a.download = download;
  const ev = document.createEvent("MouseEvents");
  ev.initMouseEvent(
    "click",
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  a.dispatchEvent(ev);
}
