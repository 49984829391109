import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AlertDialog(props) {
  const {open, title, text, primary, secondary, onClose, onPrimary, onSecondary, focus} = props;
  return (
    <Dialog
      fullWidth={true}
      // maxWidth={460}
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!!secondary &&
        <Button onClick={() => !!onSecondary && onSecondary()} color='primary' autoFocus={focus === 'secondary' || null}>
          {secondary}
        </Button>}
        <Button onClick={!!onPrimary ? onPrimary : onClose} color='primary' autoFocus={!focus || focus === 'primary' || null}>
          {primary}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onPrimary: PropTypes.func,
  onSecondary: PropTypes.func,
  focus: PropTypes.string,
};

export default AlertDialog;