import React from 'react';
import PropTypes from 'prop-types';
import SnackBar from '@material-ui/core/Snackbar';

const DEFAULT_MESSAGE = 'Unknown error occurred!';

class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {message: DEFAULT_MESSAGE};
  }

  render() {
    const {message, onClose} = this.props;
    if (!message) { // animation delay
      setTimeout(() => message === this.state.message && this.setState({message: DEFAULT_MESSAGE}),
        200);
    } else if (message !== this.state.message) {
      setTimeout(() => this.setState({message}));
    }

    return (
      <SnackBar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        open={!!message}
        onClose={() => onClose()}
        autoHideDuration={5000}
        ContentProps={{'aria-describedby': 'message-id'}}
        message={<span id='message-id'>{this.state.message}</span>}
      />
    );
  }
}

Snackbar.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default Snackbar;