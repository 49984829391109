import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import LogoIcon from '../images/logo.svg';
import SignOutIcon from '../images/log_out.svg';
import LinearProgress from "@material-ui/core/LinearProgress";
import InputBase from '@material-ui/core/InputBase';

export const BACK = 'back';
export const FILTER = 'filter';
export const SEARCH = 'search';
export const SIGN_OUT = 'sign_out';
export const CANCEL = 'cancel';
const HIDDEN = {visibility: 'hidden'};
const VISIBLE = {visibility: 'visible'};
const VOID_ACTION = () => {
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  leftIcon: {
    marginLeft: -theme.spacing.unit * 2,
  },
  logo: {
    flexGrow: 1,
    positionRelative: true
  },
  rightIcon: {
    marginRight: -theme.spacing.unit * 2,
  }
});

function icon(type) {
  switch (type) {
    case BACK:
      return <ArrowBack/>;
    case FILTER:
      return <FilterIcon/>;
    case SEARCH:
      return <SearchIcon/>;
    case SIGN_OUT:
      return <img alt='sign-out' src={SignOutIcon}/>;
    case CANCEL:
      return <CloseIcon/>;
    default:
      return <WarningIcon/>;
  }
}

function click(action) {
  return !!action ? action : VOID_ACTION;
}

function createButton(button, key, pos, classes) {
  return (
    <IconButton
      key={key}
      style={!!button ? VISIBLE : HIDDEN}
      color='inherit'
      onClick={click(!!button && button.click)}
      className={
        pos === 'left'
          ? classes.leftIcon
          : pos === 'right'
          ? classes.rightIcon
          : null
      }>
      {icon(!!button && button.icon)}
    </IconButton>
  );
}

function Header(props) {
  const {classes, progress, search, onSearch, onSearchClose} = props;
  const searchMode = search !== undefined;
  const buttons = !!props.buttons ? props.buttons : {left: [], right: []};
  return (
    <div className={classes.root}>
      <AppBar position='fixed' color={searchMode ? 'default' : 'primary'}>
        <Toolbar>
          {searchMode && <InputBase fullWidth
                                      autoFocus
                                      style={{color: 'inherit'}}
                                      placeholder='Search'
                                      defaultValue={search}
                                      onChange={e => onSearch && onSearch(e.target.value)}
                                      onKeyDown={e => e.key === 'Escape' && onSearchClose && onSearchClose()}/>}
          {searchMode && <IconButton
            key='search'
            color='inherit'
            className={classes.rightIcon}
            onClick={() => onSearchClose && onSearchClose()}>
            <CloseIcon/>
          </IconButton>}

          {!searchMode && [0, 1, 2].map(i => createButton(buttons.left[i], `left${i}`, i === 0 ? 'left' : null, classes))}
          {!searchMode && <img className={classes.logo} src={LogoIcon} alt='logo' width='87' height='50'/>}
          {!searchMode && [2, 1, 0].map(i => createButton(buttons.right[i], `right${i}`, i === 0 ? 'right' : null, classes))}
        </Toolbar>
        {progress >= 0 ?
          <LinearProgress
            color='secondary'
            variant={progress > 5 ? 'determinate' : 'indeterminate'}
            value={Math.min(progress, 100)}/> : null}
      </AppBar>
    </div>
  );
}

const PropTypeButton = PropTypes.shape({
  icon: PropTypes.string,
  click: PropTypes.func
});

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  progress: PropTypes.number,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchClose: PropTypes.func,
  buttons: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypeButton).isRequired,
    right: PropTypes.arrayOf(PropTypeButton).isRequired,
  })
};

export default withStyles(styles)(Header);
