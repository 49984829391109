import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {addRemove, isEmptySet} from "../static/functions";

const styles = theme => ({
  scrollParent: {
    overflow: 'auto',
    height: '100%',
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing.unit * 8,
    width: '100%',
    maxWidth: theme.spacing.unit * 120,
    backgroundColor: theme.palette.background.paper,
  },
  separator: {
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit,
    height: 1,
    backgroundColor: '#A5A2A2'
  },
  checkbox: {
    marginLeft: -theme.spacing.unit * 2
  },
  itemText: {
    fontFamily: 'Roboto Slab',
    fontSize: '14px',
  },
  itemTextSmall: {
    fontFamily: 'Roboto Slab',
    fontSize: '12px',
  },
  counter: {
    textAlign: 'right',
    marginRight: theme.spacing.unit * 1
  },
});

class TwoLevelCheckList extends React.Component {
  state = {
    hidden: new Set([])
  };

  render() {
    const {classes, items, onSelect, disabled, forceExpand} = this.props;
    if (forceExpand && !isEmptySet(this.state.hidden)) setTimeout(() =>
      this.setState({hidden: new Set([])})
    );
    return (
      <div className={classes.scrollParent}>
        <div className={classes.root}>
          <Divider className={classes.separator}/>
          <List disablePadding>
            {items.map(item => {
              const {path, name, selected, items} = item;
              const id = path[0];
              return (
                <div key={id}>
                  <ListItem button
                            onClick={() => this.setState({hidden: addRemove(this.state.hidden, id)})}
                            selected={selected > 0}
                            disabled={disabled}>
                    <Checkbox className={classes.checkbox}
                              onClick={e => {
                                e.stopPropagation();
                                onSelect(path);
                              }}
                              color='primary' tabIndex={-1} disableRipple
                              checked={selected > 0}
                              indeterminate={0 < selected && selected < 1}/>
                    <ListItemText classes={{primary: classes.itemText}} primary={name}/>
                      <ListItemText primary={`${items.filter(i => i.selected).length} of ${items.length}`}
                                    classes={{primary: classes.itemText}}
                                    className={classes.counter}/>
                      {this.state.hidden.has(id)
                        ? <ExpandMore nativeColor='#4A6572'/>
                        : <ExpandLess nativeColor='#4A6572'/>}
                  </ListItem>
                  <Collapse in={!this.state.hidden.has(id)} timeout='auto' unmountOnExit>
                    {items.map(item => {
                      const {path, name, selected} = item;
                      return <ListItem key={path[0] + path[1]} button
                                       disabled={disabled}
                                       onClick={() => onSelect(path)}>
                        <Checkbox className={classes.checkbox}
                                  color='primary' tabIndex={-1} disableRipple
                                  checked={selected}/>
                        <ListItemText classes={{primary: classes.itemTextSmall}} primary={name}/>
                      </ListItem>;
                    })}
                  </Collapse>
                </div>
              );
            })}
          </List>
        </div>
      </div>
    );
  }
}

TwoLevelCheckList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  forceExpand: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(TwoLevelCheckList);