import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';

export const DOCUMENT = 'document';

const styles = theme => ({
  button: {
    position: 'fixed',
    margin: theme.spacing.unit,
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  extendedIcon: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
});

function iconFor(type, className) {
  switch (type) {
    case DOCUMENT:
    default:
      return <DocumentIcon className={className}/>;
  }
}

function Fab(props) {
  const {classes, text, icon, onClick, disabled, hide} = props;
  return (
    <div>
      {!hide &&
        <Button variant='extendedFab' color='secondary' aria-label='Generate'
                className={classes.button}
                disabled={disabled}
                onClick={() => !!onClick && onClick()}>
          {iconFor(icon, classes.extendedIcon)}
          {text}
        </Button>
      }
    </div>
  );
}

Fab.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
};

export default withStyles(styles)(Fab);