import React from "react";
import PropTypes from "prop-types";
import "./WithFilter.css";

const WithFilter = ({
                      children,
                      component: Filter,
                      filter,
                      hide,
                    }) => (
  <div className='WithFilter-container'>
    {!hide && <div className='WithFilter-filter'><Filter {...filter}/></div>}
    <div className='WithFilter-body'>
      {children}
    </div>
  </div>
);

WithFilter.propTypes = {
  children: PropTypes.any.isRequired,
  component: PropTypes.any.isRequired,
  filter: PropTypes.object,
};

export default WithFilter;

