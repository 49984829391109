import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem/ListItem";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  title: {
    display: 'inline-block',
    flexGrow: 1,
    margin: theme.spacing.unit * 2,
  },
  close: {
    float: 'right',
    margin: theme.spacing.unit * 1,
    color: theme.palette.text.primary,
  },
  selectMatched: {
    marginLeft: theme.spacing.unit * 2,
  },
  densePadding: {
    paddingLeft: theme.spacing.unit * 2,
  },
  noPadding: {
    padding: 0,
  },
  noMargin: {
    margin: 0
  }
});

const TagsFilter = props => {
  const {classes, tags, selected, extraText, extraAction, disable, onSelect, onClose} = props;
  return (
    <div>
      <Typography className={classes.title}
                  color={disable ? "textSecondary" : "textPrimary"}
                  variant='h6'>Filters</Typography>
      <IconButton className={classes.close} disabled={disable} onClick={() => !!onClose && onClose()}>
        <CloseIcon color='inherit'/>
      </IconButton>
      <List component='nav'
            subheader={<ListSubheader className={classes.densePadding} component='div'>Tags</ListSubheader>}>
        {tags.map(tag =>
          <ListItem key={tag} button dense disabled={disable} className={classes.noPadding}
          onClick={() => !!onSelect && onSelect(tag)}>
            <ListItemIcon className={classes.noMargin}>
              <Checkbox className={classes.densePadding}
                        checked={selected.has(tag)}
                        color='primary'/>
            </ListItemIcon>
            <ListItemText className={classes.noPadding} inset primary={tag}/>
          </ListItem>)}
      </List>
      {!!extraText && <Button variant='outlined'
              className={classes.selectMatched}
              disabled={disable}
              onClick={() => !!extraAction && extraAction()}>
        {extraText}
      </Button>}
    </div>
  );
};

PropTypes.Filter = {
  classes: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  selected: PropTypes.object,
  extraText: PropTypes.string,
  extraAction: PropTypes.func,
  disable: PropTypes.bool,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default withStyles(styles)(TagsFilter);