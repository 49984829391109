import queryString from 'query-string';
import {mapObj, isEmptyArr} from "../static/functions";

export const parseQuery = query => queryString.parse(query);

export const dataFromQuery = query =>
  mapObj(queryString.parse(query), (key, value) => [key, !!value ? value.split(',') : []]);

export const dataToQuery = data => {
  const obj = mapObj(data, (key, valueArr) => [key, !isEmptyArr(valueArr) ? valueArr.join(',') : null]);
  return '?' + Object.keys(obj).map(key => key + (!!obj[key] ? `=${obj[key]}` : '')).join('&');
};
