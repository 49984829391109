export const identity = (x) => (x);
export const asIs = identity;

export function isEmptyObj(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function isEmptyArr(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

export function uniqueInArr(value, index, self) {
  return self.indexOf(value) === index;
}

export function isEmptySet(set) {
  return set.size === 0;
}

export function intersection(a, b) {
  const setB = new Set(b);
  return new Set([...a].filter(x => setB.has(x)));
}

export function subtraction(a, b) {
  const setB = new Set(b);
  return new Set([...a].filter(x => !setB.has(x)));
}

export function mapObj(obj, mapper) {
  if (!obj) return obj;
  else {
    const result = {};
    Object.keys(obj).forEach(key => {
      const entryArr = mapper(key, obj[key]);
      result[entryArr[0]] = entryArr[1];
    });
    return result;
  }
}

export function filterObj(obj, filter) {
  if (!obj) return obj;
  else {
    const result = {};
    Object.keys(obj).forEach(key => {
      const required = filter(key, obj[key]);
      if (required) {
        result[key] = obj[key];
      }
    });
    return result;
  }
}

export function addRemove(set, item) {
  const set2 = new Set(set);
  if (set2.has(item)) {
    set2.delete(item)
  } else {
    set2.add(item)
  }
  return set2;
}

export function addRemoveKeys(obj, keys, defaultValue) {
  keys.forEach(key => key in obj ? delete obj[key] : obj[key] = defaultValue);
  return obj;
}

export function removeKeys(obj, keys) {
  keys.forEach(key => {if (key in obj) delete obj[key]});
  return obj;
}

export function updateKey(obj, key, value) {
  obj[key] = value;
  return obj;
}

export function arrayLast(arr) {
  return isEmptyArr(arr) ? null : [...arr][arr.length - 1]
}