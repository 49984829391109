import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {isEmptyArr} from "../static/functions";

const styles = theme => ({
  container: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 8
  },
  card: {
    width: theme.spacing.unit * 43,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing.unit * 6,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 1,
  },
  field: {
    margin: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit * 1,
    marginRight: -theme.spacing.unit * 0.4,
  },
  endAdornment: {
    marginEnd: -theme.spacing.unit * 2,
    marginStart: -theme.spacing.unit,
  }
});

function Login(props) {
  const {classes, badCredentials} = props;

  return (
    <div className={classes.container}>
      <Typography variant='h4'>
        Welcome to Dods Proofing
      </Typography>
      <Card square className={classes.card}>
        <CardContent>
          <Formik
            onSubmit={(values) => {
            }}
            isInitialValid={false}
            initialValues={{
              username: '',
              password: '',
              showPassword: false,
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .email('Email is not valid')
                .required('Please enter email'),
              password: Yup.string()
                .required('Please enter password')
            })}
          >{props => {
            let {
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <form noValidate autoComplete='off'
                    method='post'
                    className={classes.form}
                    onSubmit={(!!values.username && isEmptyArr(Object.keys(errors))) ? null : handleSubmit}>
                <TextField required
                           fullWidth
                           onChange={handleChange}
                           id='username'
                           label='Username'
                           name='username'
                           autoFocus
                           value={values.username}
                           className={classes.field}
                           variant='outlined'
                           error={!!touched.username ? !!errors.username : badCredentials}
                           helperText={!!touched.username && !!errors.username && errors.username}
                           InputProps={{
                             endAdornment:
                               <InputAdornment
                                 className={classes.endAdornment}
                                 position='end'>
                                 <IconButton disableRipple disabled>
                                   {(!!touched.username ? !!errors.username : badCredentials) &&
                                   <ErrorIcon color='error'/>}
                                 </IconButton>
                               </InputAdornment>, // should be here because of padding bug
                           }}
                />
                <TextField required
                           fullWidth
                           onChange={handleChange}
                           id='password'
                           label='Password'
                           name='password'
                           value={values.password}
                           type={values.showPassword ? 'text' : 'password'}
                           className={classes.field}
                           variant='outlined'
                           error={badCredentials || (!!touched.password && !!errors.password)}
                           helperText={!!touched.password && !!errors.password ? errors.password : badCredentials && 'Bad credentials'}
                           InputProps={{
                             endAdornment: (
                               <InputAdornment className={classes.endAdornment} variant='filled' position='end'>
                                 <IconButton
                                   aria-label='Toggle password visibility'
                                   onClick={() => setFieldValue('showPassword', !values.showPassword)}>
                                   {(!!touched.password ? errors.password : badCredentials) ?
                                     <ErrorIcon color='error'/> :
                                     values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                 </IconButton>
                               </InputAdornment>
                             ),
                           }}
                />
                <Button fullWidth
                        type='submit'
                        disabled={isSubmitting}
                        variant='contained' color='secondary' className={classes.button}>
                  Login
                </Button>
              </form>
            );
          }}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  badCredentials: PropTypes.bool,
};

export default withStyles(styles)(Login);