import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing.unit * 8,
    width: '100%',
    maxWidth: theme.spacing.unit * 120,
    backgroundColor: theme.palette.background.paper,
  },
  separator: {
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit,
    height: 1,
    backgroundColor: '#A5A2A2'
  },
  checkbox: {
    marginLeft: -theme.spacing.unit * 2
  },
  itemText: {
    fontFamily: 'Roboto Slab',
    fontSize: '14px',
  }
});

function SimpleCheckList(props) {
  const {classes, items, selected, onSelect, disabled} = props;
  return (
    <div className={classes.root}>
      <Divider className={classes.separator}/>
      <List disablePadding>
        {items.map(item => {
          const [id, name, tags] = item;
          return (
            <ListItem key={id} role={undefined} button
                      onClick={() => !!onSelect && onSelect(id)}
                      selected={selected.includes(id)}
                      disabled={disabled}>
              <Checkbox className={classes.checkbox}
                        color='primary' tabIndex={-1} disableRipple
                        checked={selected.includes(id)}/>
              <ListItemText classes={{primary: classes.itemText}} primary={name}/>
              <ListItemSecondaryAction>
                <ListItemText secondary={tags}/>
              </ListItemSecondaryAction>
            </ListItem>);
        })}
      </List>
    </div>
  );
}

SimpleCheckList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(SimpleCheckList);