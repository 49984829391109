import download from "./utils/navigate";

const fetchX = (showMessage, setProgress) => (input, init) =>
  fetch(input, init)
    .then(response => response.ok
      ? Promise.resolve(response)
      : Promise.reject(`Error ${response.status}: ${response.statusText}`))
    .catch(e => {
      setProgress(-1);
      showMessage(e.toString());
      return Promise.reject(e);
    });

export const fetchInitialData = (showMessage, setProgress) => (setServices) =>
  fetchX(showMessage, setProgress)('/api/services')
    .then(response => response.json())
    .then(setServices);

export const fetchDetailedData = (showMessage, setProgress) => (detailedIds, setServices) =>
  fetchX(showMessage, setProgress)('/api/services?detailed=' + [...detailedIds].reduce((acc, i) => `${acc},${i}`, ''))
    .then(response => response.json())
    .then(setServices);

export const checkJobStatus = (showMessage, setProgress) => (jobId, getActiveJobId, setActiveJobId, clearJobId) => {
  if (jobId !== getActiveJobId()) {
    setActiveJobId(jobId);
    if (!jobId) {
      setProgress(-1);
    } else {
      setProgress(0);
      getJobStatus(showMessage, setProgress)(jobId, getActiveJobId, clearJobId);
    }
  }
};

const getJobStatus = (showMessage, setProgress) => (jobId, getActiveJobId, clearJobId) =>
  fetchX(showMessage, setProgress)(`/api/status/${jobId}`)
    .then(response => response.json())
    .then(job => {
      if (job.id === getActiveJobId()) { // should be actual job response not some previous
        switch (job.status) {
          case 'ZIPPED':
            clearJobId();
            fetchX(showMessage, setProgress)(`/api/download/${job.id}`)
              .then(response => response.json())
              .then(info => download(info.url, info.filename));
            break;
          case 'NOT_FOUND':
            clearJobId();
            showMessage("Requested documents can't be found!");
            break;
          case 'TERMINATED':
            clearJobId();
            showMessage("Failed to zip files!");
            break;
          default:
            const progress = !!job.progress
              ? (job.progress.total - job.progress.left) * 100 / job.progress.total
              : 0;
            setProgress(progress);
            setTimeout(() => getJobStatus(showMessage, setProgress)(job.id, getActiveJobId, clearJobId), 2000);
        }
      }
    });

export const generateDocuments = (showMessage, setProgress) => (serviceMap, setJobId) => {
  setProgress(0);
  return fetchX(showMessage, setProgress)('/api/generate', {
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
    body: JSON.stringify(serviceMap)
  })
    .then(response => response.json())
    .then(job => setJobId(job.id));
};

export const cancelJob = (showMessage, setProgress) => (jobId, clearJobId) =>
  fetchX(showMessage, setProgress)(`/api/cancel/${jobId}`, {method: 'POST'})
    .then(() => {
      clearJobId();
      setProgress(-1);
    });