import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4A6572',
    },
    secondary: {
      main: '#FFB74D',
    },
    text: {
      primary: '#232F34',
      secondary: '#A5A2A2',
    }
  },
  typography: {
    useNextVariants: true,
    h4: {
      fontWeight: 500,
      color: '#344955',
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#EDF9FF',
        color: '#344955'
      },
    },
    MuiListItem: {
      selected: {
        backgroundColor: 'rgba(52, 73, 85, 0.08) !important',
      },
      button: {
        color: 'rgba(52, 73, 85, 0.32)',
        '&:hover': {
          backgroundColor: 'rgba(52, 73, 85, 0.04)'
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: '#464242'
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#344955',
      }
    },
    MuiDialog : {
      paper: {
        borderRadius: 0
      }
    },
    MuiDialogContentText: {
      root: {
        color: '#232F34'
      },
    },
  },
});

export default theme;